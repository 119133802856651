import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function Aboutus() {
  const isMobile = useIsMobile();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"30px"}>
            <h2 style={{ textAlign: "center" }}>About Us</h2>
          </Grid>
        </Grid>
      </Box>

      <Box className="about-page">
        <Grid
          container
          columnSpacing={3}
          className="row"
          justifyContent={"center"}
          marginBottom={"50px"}
        >
          <Grid xs={12} md={10} textAlign={"center"} marginBottom={"12px"}>
            <Box className="border-box">
              <h5>
                Say hello to a new way of comparing insurance policies from
                various insurers across India! Compare and buy insurance
                products like Term Insurance, Health Insurance, General
                Insurance and Investment Plans which are best for you with 1
                Click Policy - Powered by Swastika Insurance Broking Services
                Private Ltd (SIBL), an IRDA registered subsidiary company of
                Swastika Group.
              </h5>
            </Box>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <p>
              Buying from 1clickpolicy not only gives you financial safety, but
              also the rich experience of our parent company Swastika Investmart
              Ltd, one of India's largest and oldest financial broking
              powerhouses. We promise you simplified insurance terms and
              conditions, hassle-free and stress-free claims and best offers
              from top insurers. Swastika Group has carried itself with rich
              experience of 28+ years in financial services. This new addition
              of insurance makes us a complete financial hub thereby providing
              all financial solutions under one roof.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" marginBottom={"40px"}>
          <Grid xs={12} md={6} className="mb-margin2">
            <h4>An Insurance Broker that only cares about your smiles...</h4>
            <p className="mb-2">
              We are wholly committed to provide value to our customers by
              understanding their insurance needs beyond what an insurance agent
              or a contemporary insurance aggregator would do; be it for car
              insurance, auto insurance, business insurance, commercial
              insurance, medical insurance, health insurance, public liability
              insurance, term plan insurance, corona insurance or home
              insurance, we provide innovative, cost effective,
              customer-oriented and customized solutions to ensure total
              customer satisfaction.
            </p>
            <p>
              We provide direct insurance broking for corporates and retail
              customers and offer wide range of products in non-life and life
              insurance. The life insurance retail products cover Children's
              Plans, Endowment, Money Back, Retirement Plans, Term, Unit Linked
              Plans and Whole-Life Plans. The Group Policies include Credit
              Cover, Employees Term Cover, Gratuity, Healthcare Cover and
              Superannuation. Under the non-life insurance category, Personal,
              Industrial, Commercial, Social and Liability products are
              available in addition.
            </p>
          </Grid>
          <Grid xs={12} md={6} textAlign={"center"}>
            <img
              src="./images/about-img1.jpg"
              alt="contact"
              className="complaint-img"
              style={{
                maxWidth: "500px",
                width: "100%",
                textAlign: "center",
              }}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" marginBottom={"40px"}>
          <Grid xs={12} md={6} className="mb-margin2">
            <Box className="bluBox">
              <h4>Value Added Claims Consultancy.</h4>
              <p className="mb-4">
                We also offer value-added services like claims consultancy
                services to ensure you get the best rate of insurance claims
                settlements and industry-leading claims assistance. Our experts
                are a team of professional consultants with added expertise of
                doctors, lawyers with years of enriching experience in insurance
                domain, thereby offering end to end claims solutions.
              </p>
              <h4>Our Purpose</h4>
              <p>We empower people to earn financial freedom quickly</p>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <h4>Our Core Values</h4>
            <ul className="coreValues-list">
              <li>Customers are our priority number one</li>
              <li>We are determined to honour our commitments</li>
              <li>We are a trustworthy and truthful tribe</li>
              <li>Politeness is our language</li>
              <li>We are disciplined, presentable and always on time</li>
              <li>We keep our eyes and ears open to learn</li>
              <li>We are energetic and happy at all times</li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"40px"}>
          <Grid xs={12} className="ourTeam-sec">
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Core Team" value="1" />
                    <Tab label="Sales & Service Team" value="2" />
                    <Tab label="Technology Team" value="3" />
                    <Tab label="Accounts Team" value="4" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <ul className="teamList">
                    <li>
                      <Box className="member-img">
                        <img src="./images/avatar.jpg" alt="" />
                      </Box>
                      <h6>Sunil Nyati</h6>
                      <p>Managing Director</p>
                    </li>
                    <li>
                      <Box className="member-img">
                        <img src="./images/female_avatar.jpg" alt="" />
                      </Box>
                      <h6>Manisha Jumrani</h6>
                      <p>Principal Officer</p>
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel value="2">
                  <ul className="teamList">
                    <li>
                      <Box className="member-img">
                        <img src="./images/avatar.jpg" alt="" />
                      </Box>
                      <h6>Gireesh Udaiyar</h6>
                      <p>North & South Head</p>
                    </li>
                    <li>
                      <Box className="member-img">
                        <img src="./images/avatar.jpg" alt="" />
                      </Box>
                      <h6>Dharamendra Singh</h6>
                      <p>East & West Head</p>
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel value="3">
                  <ul className="teamList">
                    <li>
                      <Box className="member-img">
                        <img src="./images/avatar.jpg" alt="" />
                      </Box>
                      <h6>Anuj Singh</h6>
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel value="4">
                  <ul className="teamList">
                    <li>
                      <Box className="member-img">
                        <img src="./images/avatar.jpg" alt="" />
                      </Box>
                      <h6>Prakash Chander</h6>
                    </li>
                  </ul>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Aboutus;
